<template>
    <div class="page">
        <el-backtop><i class="el-icon-caret-top"></i></el-backtop>
        <img src="../../assets/images/clian/clian1.png" alt="" srcset="" class="imgs1">
        <img src="../../assets/images/clian/clian2.png" alt="" srcset="" class="imgs2">
        <img src="../../assets/images/clian/clian3.png" alt="" srcset="" class="imgs3">
        <img src="../../assets/images/clian/clian4.png" alt="" srcset="" class="imgs4">
        <img src="../../assets/images/clian/clian5.png" alt="" srcset="" class="imgs5">
        <img src="../../assets/images/wenli-3.png" alt="" srcset="" class="wl3">
    </div>
</template>
<script>
export default {
    data(){
        return {
        }
    },
    created(){
    },
    methods: {
    }
    
}
</script>
<style lang="scss" scoped>
.page{
    background: #F8F9FA;
    position: relative;
    // display: inline-block;
    // width: 100%;
    .imgs1{
        width: 1644px;
        height: 540px;
        margin-top: 104px;
        margin-left: 122px;
        display: block;
        position: relative;
        z-index: 1;
    }
    .imgs2{
        width: 1920px;
        height: 816px;
        margin-top: 245px;
        display: block;
        position: relative;
        z-index: 1;
    }
    .imgs3{
        width: 1920px;
        height: 834px;
        display: block;
        position: relative;
        z-index: 1;
    }
    .imgs4{
        width: 1920px;
        height: 906px;
        margin-top: 110px;
        display: block;
        position: relative;
        z-index: 1;
    }
    .imgs5{
        width: 1920px;
        height: 1024px;
        margin-bottom: 73px;
        display: block;
        position: relative;
        z-index: 1;
    }
    .wl3{
        width: 1146px;
        height: 1043px;
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>